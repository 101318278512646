.DashboardSideNav {
  // border: solid red;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  .logo-container {
    height: 170px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;

    font-family: "Syne";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 54px;
    /* identical to box height */

    color: #ffffff;
  }
}

.subscription-button {
  width: auto;
  border: 3px solid;
  border-radius: 50%;
  border-image: linear-gradient(45deg, #DC9100, #FFD562, #F3C51F) 1;
}
.subscription-text {
   /* Fallback: Set a background color. */
   background-color: gold;
  
   /* Create the gradient. */
   background-image: linear-gradient(45deg, #DC9100, #F3C51F);
   
   /* Set the background size and repeat properties. */
   background-size: 100%;
   background-repeat: repeat;
 
   /* Use the text as a mask for the background. */
   /* This will show the gradient as a text color rather than element bg. */
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent; 
   -moz-background-clip: text;
   -moz-text-fill-color: transparent;
}

.DashboardTopNav {
  display: flex;
  align-items: center;
  // border: solid;
  justify-content: space-between;
  padding: 0 39px;
  margin-bottom: 20px;
  .search-container {
    // border: solid;
    width: 620px;
    border-radius: 8px;
    input {
      height: 48px;
      border-radius: 15px;
      background-color: #ffffff;
    }
    .chakra-input__left-element {
      height: 48px;
    }
  }
  .profile-container {
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .notification-icon-container {
      background-color: #fff;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: #2b2b36;
      }
    }
    .chakra-avatar {
      width: 50px;
      height: 50px;
    }
  }
}
