.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  *{
    font-family: syne, sans-serif;
    font-style: normal !important;
  }
  .modal_container {
    background: white;
    padding: 20px 40px;
    padding-bottom: 40px;
    border-radius: 8px;
    max-width: 550px;
    min-height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    z-index: 1050;
    text-align: center;
  }
  
  .options {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .option {
    width: 40%;
    padding: 10px;
    border-radius: 15px;
    text-align: center;
    overflow: hidden;
  }
  .option h3 {
    margin-top: 10px;
    font-family: 'Quicksand';
    font-style: bold;
    font-weight: 900;
  }
  
  .option p {
    font-size: 0.9em;
  }
  .continue.disabled{
    background-color: gray;
    border-radius: 25px;
  }
  .option.selected {
    border-radius: 25px !important;
  }
  .option.selected img {
    border-radius: 20px 20px 0 0px;
  }

  .continue {
    background-color: blue;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
  }
  
  .toggle-modal {
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* You may need to adjust the image sizes or add classes for them */
  .options .option.selected{
    border: 3px solid blue !important;
    border-radius: 7px;
  }