.ProgressBarComponent {
  // border: solid;
  width: 135px;
  height: 135px;
  margin: auto;
  h2 {
    flex-direction: column;
    align-items: center !important;
    div {
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: flex-end;

      /* Grey/Grey */

      color: #808080;
    }
  }
}
