.FileUpload {
  .file-card {
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px dashed #cbd5e0;
    background-color: #f4f4f4;
    // width: 480px;
    // min-height: 200px;
    margin: 20px auto;
    width: 100%;
    height: 176px;
    .file-inputs {
      position: relative;
      margin-bottom: 1.5em;

      input {
        position: relative;
        text-align: right;
        opacity: 0;
        z-index: 2;
        cursor: pointer;
        height: 100%;
        max-width: 100%;
      }

      width: 140px;
      height: 30px;

      /* Grey/Grey */

      background: #808080;
      border-radius: 30px;
      button {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        // color: #fff;
        // background-color: var(--primary-Blue);
        // font-size: 1.1rem;
        cursor: pointer;
        // border-radius: 4px;
        border: none;
        outline: none;
        // padding: 1em;
        transition: background-color 0.4s;
        box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);

        // text
        // font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        /* identical to box height */

        text-align: center;

        color: #f1f1f1;
        // i {
        //     width: 1.5em;
        //     height: 1.5em;
        //     padding: 0.4em;
        //     background-color: #fff;
        //     color: #f55e30;
        //     border-radius: 50%;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     margin-right: 0.8em;
        //     font-size: 0.8em;
        // }
      }

      // &:hover {
      //     button {
      //         background-color: #f15120;
      //     }
      // }
    }
  }

  .main {
    font-weight: bold;
    margin-bottom: 0.4em;
  }

  .info {
    // font-size: 0.8rem;
    // font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    text-align: center;

    color: #808080;
  }
}
