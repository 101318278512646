.FAQPage {
  h1 {
    margin-bottom: 20px;
    font-family: "Syne";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    color: #111111;
  }
  button {
    height: 70px;
  }
}
