.FormsComponent {
  .FormInputField {
    // border: solid;
    display: flex;
    flex-direction: column;
    margin: 20px 0;

    label {
      height: 25px;
      font-family: "Quicksand";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;
      margin-left: 5px;
      color: #111111;
    }
    span {
      color: red;
      margin-top: 8px;
    }
    input {
      border: solid 1 #ddd;
      /* Rectangle 2 */
margin-top: 8px;
      width: 100%;
      height: 40px;
      padding: 0 10px;

      /* Grey/Grey */

      border: 1px solid #808080;
      border-radius: 10px;
    }
    input:focus {
      outline: none;
    }
    input.error {
      border: solid 1px red;
      color: red;
    }
  }
.error {
  color: red;
}

  button{
    /* Button 1 */


/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 15px 24px;
gap: 4px;
margin: auto;
// position: absolute;
max-width: 358px;
width: 90%;
height: 53px;
border: none;
// left: 541px;
// top: 497px;
/* Blue Plimary Colors/Blue */

background: #0000FF;
color: #fff;
border-radius: 50px;

transition: all .3s ease-in;
  }
  button:hover{
font-size: 20px;
opacity: 0.5;
    max-width: 365px;
width: 95%;  }
 button:disabled,
button[disabled]{
      opacity: 0.6;
  }
}

.WithdrawTabFormComponent{
  label{
    font-family: 'Quicksand';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 18px;
/* identical to box height, or 100% */

display: flex;
align-items: center;
letter-spacing: 0.25px;

color: #000000;
  }
  input{
    height: 48px;
  }
  button.submit{
    display: flex;
// flex-direction: row;
justify-content: center;
align-items: center;
padding: 20px 40px;
gap: 4px;

margin: 0 auto;
margin-top: 20px;

width: 390px;
height: 63px;

/* Blue Plimary Colors/Blue */

background: #0000FF;
border-radius: 50px;

// text
font-family: 'Quicksand';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;
/* identical to box height */

text-align: center;
text-transform: capitalize;

color: #FFFFFF;

  }
  button.add{
width: 36px;
height: 36px;

border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
background: #FFFFFF;
box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15);
  }

}