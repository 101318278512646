.FinancePage{
  margin-top: 48px;
  .top-card-container{
width: 290px;
height: 102px;
display: flex;
flex-direction: column;
gap: 10px;
background: #FFFFFF;
border-radius: 20px;
padding: 12px 15px;
p{
  
font-family: 'Quicksand';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
/* identical to box height */


color: #000000;

opacity: 0.6;
}
.icon-container{
width: 28px;
height: 28px;

background: #ccccff;
// opacity: 0.2;

border-radius: 100%;
display: flex;
justify-content: center;
align-items: center;
z-index: 2;
position: relative;
&::after{
  content: "₦";
  display: inline-flex;
 
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 100%;
  width: 18px;
  position: absolute;
height: 18px;
// z-index: 1;
margin: auto;
background: #a3a3ff;
// background: rgba(255, 255, 255);
 color: #0000FF ;
  font-weight: 800;}
}
h1{
  font-family: 'Quicksand';
font-style: normal;
font-weight: 700;
font-size: 27px;
line-height: 34px;
/* identical to box height */

display: flex;
align-items: flex-end;

/* Grey/Dark Grey */

color: #111111;

}
  }

  .transaction-section{
    .top{
      display: flex;
      justify-content: space-between;
    }
  }

  .side-col{
width: 100%;
height: 560px;
padding: 30px 46px;

background: #FFFFFF;
border-radius: 20px;

input{
width: 100%;
height: 38px;

background: #D7D7D7;
border-radius: 10px;
// text
font-family: 'Quicksand';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
/* identical to box height */


/* Grey/Dark Grey */

color: #111111;
}

.chat-section{
  margin-top: 30px;
}
  }
}
.TransactionCard{
  
/* Auto layout */

display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
padding: 10px 20px;
gap: 32px;
// div{
// display: flex;
//   flex-direction: column;
// }

width: 100%;
height: 48px;
margin-top: 20px;

background: rgba(255, 255, 255, 0.3);
border: 1px solid rgba(0, 0, 0, 0.2);
border-radius: 10px;
  .icon-container{
    // border: solid;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 10%;
  }
  h3{
    font-family: 'Quicksand';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 18px;
/* identical to box height */


color: #000000;
  }
  p{
    font-family: 'Quicksand';
font-style: normal;
font-weight: 600;
font-size: 8px;
line-height: 10px;
/* identical to box height */


color: #000000;

opacity: 0.5;
  }
}