.AccountSetUpCard {
  // border: solid;
  cursor: pointer;
  position: relative;
  // width: 771px;
  height: 168px;
  left: 2px;
  top: -1px;
  padding: 16px 23px;
  /* Grey/Dark Grey */

  background: #111111;
  color: #fff;
  border-radius: 14.0097px;
  .box-design-image {
    // border: solid red;
    position: absolute;
    top: -3px;
    right: 62px;
    // width: 100%;
  }

  h1 {
    font-family: "Syne";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    display: flex;
    align-items: center;
    margin: 5px 0 0 0;
    /* White/White */

    color: #ffffff;
  }
  p {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
  }

  .stepper-container {
    background: #ffffff;
    .HorizontalNonLinearStepper {
      .name {
        color: #111111;
      }
      .dash {
        border: 1px solid #111111;
      }
    }
  }
}

.stepper-container {
  width: 578px;
  height: 34px;
  background-color: white;
  margin-top: 19.12px;
  display: inline-flex;
  align-items: center;
  width: 100%;

  border-radius: 10px;
}

.indicator-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: solid;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 20px;
  .indicator {
    /* Rectangle 55 */

    width: 32.01px;
    height: 4px;

    /* Light Blue */

    background: #ceced4;
    border-radius: 5px;

    cursor: pointer;
  }
  .indicator.active {
    background: #145c9e;
  }
}
// .DrawerComponent{
.css-1o3pyl4 {
  // border:solid red;
  // overflow:scroll !important;
  // max-width:  585px !important;
  max-width: 470px !important;
  border-radius: 20px 0 0 20px !important;
  .top-header {
    // margin-top: 50px;
    text-align: center;

    h2 {
      /* Website Fonts/Headings/Third title */

      font-family: "Syne";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 36px;
      /* identical to box height */

      /* Grey/Dark Grey */

      color: #111111;
    }
    p {
      font-family: "Quicksand";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      text-align: center;

      /* Grey/Dark Grey */
      max-width: 300px;
      // border: solid;
      margin: 0 auto;
      color: #111111;
    }
  }

  .stepper-container {
    width: 97%;
    margin: 0 auto;
    margin-top: 15px;
    background: #111111;
    .HorizontalNonLinearStepper {
      padding: 0 10px;
      .name {
        color: #ffffff;
      }
      .dash {
        width: 15px !important;
        border: 1px solid #fff;
      }
    }
  }
  .form-container {
    // border:solid red;
    // overflow:hidden;
    z-index: 0px;
    width: 90%;
    select,
    input {
      z-index: 0px !important;
      background: #f3f3f4;
      border-radius: 5px;
      height: 40px;
    }
  }
  .next-button {
  // position: absolute;
  // width: 390px;
  width: 300px;
  height: 50px;

  /* Blue Plimary Colors/Blue */

  background: #0000ff;
  border-radius: 50px;

  // text
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;
  text-transform: capitalize;

  color: #ffffff;

  &:hover {
    background: #0000ff !important;
  }
}
}


.IntroductionStep {
  margin: 0;
  padding: 0;
  div {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    padding: 16px 20px 32px;
    gap: 4px;

    width: 100%;

    background: #f3f3f4;
    border-radius: 5px;

    b {
      font-family: "Quicksand";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 160%;
      /* or 26px */

      /* Dark Blue */

      color: #21243a;
    }
    // text

    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    /* or 27px */

    /* Dark Blue */

    color: #21243a;
  }
}

.SuccessStep {
  h2 {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 160%;
    /* or 51px */

    text-align: center;

    /* Grey/Dark Grey */

    color: #111111;
  }
  p {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    /* Dark Blue/1 */

    color: #3a3f58;
    margin: 20px 0;
  }
}

.HorizontalNonLinearStepper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  .stepper-step {
    display: flex;
    color: #111111;
    align-items: center;
    gap: 10px;
    .icon-container {
      border: solid 1px;
    }
    .name {
      font-family: "Quicksand";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: center;
    }
  }
  .dash {
    // position: absolute;
    width: 30px;
    height: 0px;
    left: 171px;
    top: 136px;
  }
}

.file-select-label-button {
  // border: solid 1px red;
  margin: 0 5px !important;
  padding: 0 6px !important;
  display: flex !important;
  height: 30px;
  justify-content: center;
  align-items: center;
  width: 100%;
  // text
  background: #0000ff;
  border-radius: 50px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}
