.Dashboard {
  // border: solid red;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  background-color: #172337;
  // overflow: hidden;
  .page-body-wrapper {
    display: flex;
    // border: solid green;
    width: 100%;

    .side-nav {
      // border: solid;
      // position: sticky;
      // top: 0px;
      // left: 0px;
      // width: 100%;
      min-width: 265px;
      color: #fff;
      height: 100%;
      position: fixed;
    }
    .main-dashboard-page {
      // border: solid;
      background-color: #f1f1f1;
      width: 80%;
      margin-left: 20%;
      border-radius: 50px 0 0 50px;
      min-height: 100%;
      // overflow-y: scroll;
      // padding-left: 39px;
      padding: 32px 0;
    }
  }
}
.session_expired {
  color: red;
  font-weight: 600;
  font-size: 25px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a {
    color: blue;
    text-decoration: underline;
    font-size: 20px;
  }
}
