.tab-button {
  // .tap-button{
  // border:red  solid !important;

  width: 50%;
  border-radius: 0 !important;
  background: #c0c0c0;
  // button{
  //   border: solid;
  // }
}
.tab-button[aria-selected="false"] {
  color: #1e1e1e !important;
  // background-color: #918ca2;
}
.tab-button[aria-selected="true"] {
  color: #111 !important;
  background-color: rgb(245, 245, 249);
}
.tab-button:first-child {
  border-radius: 40px 0 0 40px !important;
}

.tab-button:last-child {
  border-radius: 0 40px 40px 0 !important;
}
.tabs__tab-panel {
  border: none !important;
}
// .MainDrawerComponent{
// min-width: 800px !important;
.selected-screen {
  position: relative;
  font-family: "Quicksand";
  // margin-left: 20px;
  font-style: normal;
  color: #000000;
  min-height: 100%;
  // border: solid;
  display: inline-flex;
  flex-direction: column;
  .close-button {
    // border: solid;
    font-size: 30px;
  }
  .b {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    margin: 0;
  }
  .span {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: block;
  }

  .p {
    // height: 98px;
    padding: 17px 20px;
    margin: 20px 0;
    background: #ddd;
    // background: #FFFFFF;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    // margin: 0 auto;
    // text

    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
  .action-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    padding-bottom: 20px;

    // position: absolute;
    bottom: 30px;
    width: 100%;

    button {
      padding: 20px 40px;

      width: 311px;
      height: 63px;

      border-radius: 50px;

      // text
      font-family: "Quicksand";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */

      text-align: center;
      text-transform: capitalize;

      color: #ffffff;
    }
    button.reject {
      background: #ff0000;
    }
    button.approve {
      background: #000aff;
    }
  }
}
.bank-card-container {
  // border-bottom: 1px solid rgba(0, 0, 0, 0.3);

  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  color: rgba(0, 0, 0, 0.6);
  b {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    color: #000000;
  }
}
.TransferCard,
.MilestoneCard {
  cursor: pointer;
  // width: 708px;
  // height: 72px;
  border: solid 1px #ddd;
  margin: 20px 0;

  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  // text
  font-family: "Quicksand";
  font-style: normal;
  width: 100%;
  h2.transferCard-header {
    height: 59px;
    display: inline-flex;
    align-items: center;
  }
  h2,
  b {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    /* DMV/Black */

    color: #23262f;
  }
  b {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    color: rgba(17, 17, 17, 0.7);
    width: 220px;
    height: 27px;
  }
  .long-des {
    align-items: center;
    display: inline-flex;
    width: 522px;
    height: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */

    /* Grey/Dark Grey */

    color: #111111;
  }
  .date {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    /* Grey/Dark Grey */

    color: #111111;
  }
  .time {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
  hr {
    width: 44px;
    height: 0px;

    border: 1px solid rgba(0, 0, 0, 0.2);
    transform: rotate(90deg);
  }
  .flex-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    /* Blue Plimary Colors/Blue */

    color: #0000ff;
  }
}
.NotificationCard {
  background-color: #ddd;
  display: flex;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
  // height: 136px;

  text-align: start;
  box-shadow: 0px 1px 0px rgba(105, 115, 134, 0.2);
  border-radius: 10px;
  padding: 20px 10px;
  .icon-container {
    // border: solid;
    font-size: 30px;
  }
  font-family: "Quicksand";
  font-style: normal;
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: #111111;
  }
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;

    color: rgba(17, 17, 17, 0.79);
    margin: 10px 0;
  }
  .date {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    margin-top: 5px;
    /* identical to box height, or 143% */

    /* Gray / Gray-500 */

    color: #697386;
  }
}
// }
.chakra-slide {
  // border:solid;
  // max-width: 800px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  padding: 40px 10px;
}
.MainDrawerComponent {
  background-color: red !important;
}
.ApplyForProjectTab,
.CreatePortfolioTab {
  // border:solid;
  padding: 0 20px;
  h1 {
    font-family: "Syne";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    /* Grey/Dark Grey */

    color: #111111;
  }
  sub {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;

    color: rgba(17, 17, 17, 0.5);
  }
  p {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: justify;

    /* Grey/Dark Grey */

    color: #111111;

    opacity: 0.6;
  }
  b {
    font-family: "Syne";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    /* Grey/Dark Grey */

    color: #111111;
  }
  label {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    /* Grey/Dark Grey */

    color: #111111;
  }
  .desc {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: justify;

    /* Grey/Dark Grey Faded */

    color: rgba(17, 17, 17, 0.5);
    display: block;
    width: 684px;
    margin: 10px auto;
  }

  input[disabled] {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    /* identical to box height */

    /* Grey/Dark Grey */
    opacity: 1;
    color: #000000;
  }

  button.submit-button {
    /* Button 1 */

    /* Auto layout */

    display: flex;
    // flex-direction: row;
    justify-content: center;
    align-items: center;
    // padding: 20px 40px;
    // gap: 4px;

    // position: absolute;
    width: 390px;
    height: 63px;
    // left: 276px;
    // top: 2170px;

    /* Blue Plimary Colors/Blue */

    background: #0000ff;
    border-radius: 50px;
    margin: 20px auto;
    // text
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    text-transform: capitalize;

    color: #ffffff;
  }
}
.PortfolioDetailesTab {
  padding: 0 20px;
  .portfolio-image-container {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    img {
      width: 182.99px;
      height: 155px;
      object-fit: cover;
    }
  }
  font-family: "Quicksand";
  font-style: normal;
  letter-spacing: 0.25px;
  line-height: 18px;
  b {
    font-weight: 600;
    font-size: 16px;
    /* identical to box height, or 112% */

    margin: 20px 0;
    color: #000000;
  }
  p {
    font-weight: 400;
    font-size: 14px;

    color: rgba(17, 17, 17, 0.7);
    margin: 20px 0;
  }
  sup {
    font-weight: 400;
    font-size: 12px;
    margin-top: 10px;
    display: block;
    color: rgba(17, 17, 17, 0.7);
    margin: 20px 0;
  }
}

.NotificationSettingsTab {
  padding: 0 20px;
}
.PasswordSecuritySettingsTab {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;

  .g-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 0 10px;
    border: solid 2px #9e9e9e;
    border-radius: 8px;
  }
  .update-password {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 20px;
    .icon-container {
      color: #0000ff;
      font-size: 20px;
    }
    .submit-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 40px;
      gap: 4px;

      width: 390px;
      height: 55px;

      /* Blue Plimary Colors/Blue */

      background: #0000ff;
      border-radius: 50px;
      margin: 20px auto;
      margin-top: auto;
      // text

      font-family: "Quicksand";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */

      text-align: center;
      text-transform: capitalize;

      color: #ffffff;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }
}

.LanguageSettingsTab {
  padding: 0 20px;
  font-family: "Quicksand";
  font-style: normal;
  b {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.1px;

    /* Light/Gray-11 */

    color: #000000;
  }
  /* Customize the label (the container) */
  .radio_container {
    display: block;
    // border: solid;
    position: relative;
    // padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 20px 0;
    display: flex;
    flex-direction: row;

    // text

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: 0.25px;

    /* Light/Gray-11 */

    color: #000000;
  }

  /* Hide the browser's default radio button */
  .radio_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 5%;
    right: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  .radio_container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .radio_container input:checked ~ .checkmark {
    background-color: #2196f3;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .radio_container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .radio_container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
}
.PrivacyPolicySettingsTab,
.StatsSettingsTab {
  padding: 0 20px;
  font-family: "Quicksand";
  font-style: normal;
  b {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.1px;

    color: #111111;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: justify;
    letter-spacing: 0.25px;

    color: rgba(17, 17, 17, 0.7);
    padding: 16px 0;
  }
}

.DocumentsTab {
  padding: 0 20px;
}
.Documentscard {
  border: solid 3px #ddd;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin: 10px 0;
  align-items: center;
}
.BidsTab {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // padding: 0 20px;
  padding: 0;
  .ViewContractorApplication {
    // border: solid 1px;
    width: 100%;
    h3,
    h2 {
      font-family: "Syne";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height */

      /* Grey/Dark Grey */

      color: #111111;
      margin: 5px 0 8px 0;
    }
    h3 {
      font-size: 18px;
      margin: 0;
    }
    .close-button {
      font-size: 30px;
      margin-bottom: 20px;
    }
    .profile-card {
      width: 100%;
      // border: solid;
      display: flex;

      // overflow: hidden;
      // &>div{
      background: #ffffff;
      box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
      border-radius: 50px;
      // }
      .profile-image-container {
        // border: solid;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        .chakra-avatar {
          // border: solid red;
          width: 100px;
          height: 100px;
        }
      }
      .info-side {
        //  border: solid red;
        width: 100%;
        padding: 20px;

        p {
          font-family: "Quicksand";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          /* identical to box height */

          /* Grey/Dark Grey Faded */

          color: rgba(17, 17, 17, 0.5);
          margin: 5px 0 8px 0;
        }
        button {
          width: 200px;
          height: 35px;

          /* Blue Plimary Colors/Blue */

          background: #0000ff;
          border-radius: 30px;

          margin: 5px 0 8px 0;

          // text
          font-family: "Quicksand";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          // line-height: px;
          /* identical to box height */

          /* White/White */

          color: #ffffff;
        }
        // .more-info-container {
        //   // border: solid;
        //   // justify-content: center;
        //   margin-top: 30px;
        //   padding: 0 20px;
        //   display: flex;
        //   flex-wrap: wrap;
        //   // justify-content: space-evenly;
        //   gap: 20px;
        //   & > div {
        //     // border: solid;
        //     // width: 40%;
        //     display: flex;
        //     align-items: center;
        //     gap: 10px;
        //     // text

        //     font-family: "Quicksand";
        //     font-style: normal;
        //     font-weight: 700;
        //     font-size: 14px;
        //     line-height: 18px;
        //     /* identical to box height */

        //     /* Grey/Dark Grey */

        //     color: #111111;
        //     .icon-container {
        //       width: 35px;
        //       height: 35px;
        //       display: flex;
        //       justify-content: center;
        //       align-items: center;
        //       border-radius: 50%;
        //       /* White/Silver */

        //       background: #c0c0c0;
        //     }
        //   }
        // }
      }
    }
    .bid_proposal {
      margin: 20px 0;
      padding: 10px;
    }
    .project-milestones-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .milestone {
        margin-top: 20px;
        .text {
          width: 259px;
          height: 28px;
          background: #d9d9d9;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          // padding: 5px 20px;
          padding-left: 8px;
          // text

          font-family: "Quicksand";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          /* identical to box height */

          display: flex;
          align-items: center;
          text-align: justify;

          /* Grey/Dark Grey Faded */

          color: rgba(17, 17, 17, 0.5);
        }
      }
    }

    .gallery-container {
      margin-top: 20px;
      .image-container {
        width: 149px;
        height: 132px;

        border-radius: 5px;
        margin: 10px 0;
      }
    }
  }
}

.BidProfileCard {
  box-sizing: border-box;
  margin: 10px;
  width: 300px;
  // height: 192px;
  padding: 15px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  // gap: 30px;
  // align-items: center;
  justify-content: center;

  // text

  font-family: "Quicksand";
  font-style: normal;
  color: #090909;
  h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
  }
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    opacity: 0.7;
  }
  button {
    width: 124px;
    height: 34px;

    border-radius: 30px;

    // text

    font-family: "Quicksand";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
  button.profile {
    background: #0000fa;

    color: #ffffff;
  }
}
