.TostMessageComponent {
  position: fixed;
  top: 10px;
  right: 10px;
  /* bottom: 0; */
  /* flex-wrap: wrap; */
  padding: 10px 20px;
  margin: 10px 0;
  min-width: 250px;
  max-width: 500px;
  // justify-content: space-between;
  height: auto;
  /* overflow: hidden; */
  background-color: #fff;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.5);
  z-index: 100000;
  
  
  // main style
  display: flex;
  // flex-direction: column;
  gap: 20px;
  max-width: 40%;
  min-width: 300px;
  min-height: 80px;

box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
border-radius: 5px;

.icon-container{
  display: flex;
  align-items: center;
}
.text-container{
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
h3{
  font-family: 'Quicksand';
font-style: bold;
font-weight: 700;
font-size: 21px;
line-height: 31px;

color: #FFFFFF;
text-transform: uppercase;

}
p{
  font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 15px;
/* identical to box height */


color: #FFFFFF;
}
}
.close{
  position: absolute;

  top: 10px;
  font-size: 25px;

  right: 20px;
}
}

.TostMessageComponent.error {
  background: #FF284E;
  // border-left: solid red 5px;
}
.TostMessageComponent.success {
background: #32a852;
}
.TostMessageComponent.warning {
background: #FF9F47;
}
