.ViewOngoingProjectsPage {
  // border: solid;
  .header-text {
    font-family: "Syne";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */

    /* Grey/Dark Grey */
    margin: 30px 0;

    color: #111111;
  }
  .summary-container {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 58px;
  }
  .top {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    margin-top: 50px;
    h2 {
      /* Heading/H5 */

      font-family: "Plus Jakarta Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      /* identical to box height, or 26px */

      /* Base/100 */

      color: #242424;
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 145%;
      /* or 20px */

      color: #145c9e;
    }
  }
  .withdraw-container {
    // border:solid;
    width: 100%;
    margin: 0;
    margin-bottom: 12px;
    // width: 316px;
    height: 63px;

    cursor: pointer;
    /* White/White */

    background: #eee6e2;
    border-radius: 14.0097px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    // text
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 40px;
    /* or 222% */

    /* DMV/Black */

    color: #23262f;

    button {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.ProjectsSummaryCard {
  width: 249px;
  height: 129px;

  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 17px;
  cursor: pointer;
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    color: #ffffff;
  }
  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    color: #eeeeee;
  }
  .icon-container {
    width: 40px;
    height: 40px;

    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}

.NoteCard {
  width: 794px;
  // height: 81px;

  /* Base/0 */

  background: #ffffff;
  border-radius: 8px;
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 10px 15px;
  margin: 14px 0;
  .icon-container {
    /* Auto layout */

    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;

    // background: rgba(255, 105, 63, 0.2);
    border-radius: 50%;
  }
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    /* Base/100 */

    color: #242424;
  }
  span {
    /* Body/Regular/M */

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    /* or 20px */

    /* Base/80 */

    color: #707070;
  }
}
