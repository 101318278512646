.AuthPage {
  border: solid;
  min-height: 100vh;
  background-color: var(--primary-Blue);
  background-image:
    url("../../assets/images/pngwing2.svg"), url("../../assets/images/pngwing1.svg");
  background-position: 0px 0px, 0px 0px;
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  display: flex;
  flex-direction: column;
  .logo {
    // border: solid;
    font-family: "Syne";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    margin-top: auto;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .down-Links {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 4px;
    margin-bottom: auto;
    color: var(--text-muted);
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    a{
      font-weight: 700;
      text-decoration: none;
      color: #ffffff;
    }
    a:hover{
      font-weight: 900;
    font-size: 23px;
    }
  }
  .form-card {
    // border: solid red;
    max-width: 565px;
    width: 100%;
    // min-height: 400px;
    margin: 0 auto;
    padding: 20px 50px;
    padding-bottom: 40px;
    background: #ffffff;
    border-radius: 20px;
    .form-title {
      // border: solid;
      text-align: center;
      margin: 30px 0 30px 0;
      /* Website Fonts/Headings/Third title */
      font-family: "Syne";
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 36px;
      /* identical to box height */

      /* Grey/Dark Grey */

      color: #111111;
    }
  }
}
